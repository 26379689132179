import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
	GET_CAMERA_IDS_QUERY,
	DASHBOARD_QUERY,
	DASHBOARD_GRAPHS_QUERY,
	GET_DRIVERS_LIST_WITH_COMPANY_DATA,
	GET_DRIVERS_TRIPS_LIST,
	DASHBOARD_QUERY_BOTTOM_DRIVERS,
	DASHBOARD_GRAPHS_STATS_PER_DAY,
	GET_DRIVERS_LIST_WITH_COMPANY_DATA_DRIVER,
} from '../queries/queries';
import {
	Company,
	VideoEntry,
	Address,
	CameraStatus,
	TripDetails,
	riskFactorItemScore,
	Distributions,
} from '../types/data-types';
import { environment } from '../../environments/environment';
import { LatLon, LatLng } from '../types/geo-data.types';
import { IndexedDBService } from './indexedDB.service';
import * as AWS from 'aws-sdk';
import { AlertService } from './alert.service';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export interface DataResponse<DataType> {
	status: string;
	data: DataType;
}

@Injectable()
export class DataService {
	driversLists = new Subject();
	driversListWithComp = new Subject();
	selectDriversLists = new Subject();
	sendloaderRequest = new Subject();
	companyOverallData = new Subject();
	companydashboardDrivers = new Subject();
	companyGraphData = new Subject();
	driverCompanyStats = new Subject();
	cameraIdsQuery: QueryRef<any>;
	dashboardQuery: QueryRef<any>;
	driversListQuery: QueryRef<any>;
	tripsDriversListQuery: QueryRef<any>;
	driversListWithCompQuery: QueryRef<any>;
	selDateRange = new Subject();
	tripsdriversLists = new Subject();
	globalLoading$ = new BehaviorSubject<boolean>(false);
	private messageSource = new BehaviorSubject('default message');
	private distributionsTrigger$ = new BehaviorSubject<{
		status: string;
		distributions: Distributions;
	}>(null);
	currentMessage = this.messageSource.asObservable();

	static addressResultCache = new Map<string /* lat,lon 형식으로 인코딩된 값 */, Address>();
	sw = false;
	type = '';

	private indexedDBService: IndexedDBService = new IndexedDBService();
	private s3: AWS.S3;

	constructor(
		private apollo: Apollo,
		private http: HttpClient,
		private alertService: AlertService
	) {
		AWS.config.update({
			region: environment.S3_REGION,
			credentials: new AWS.Credentials({
				accessKeyId: environment.S3_AWS_ACCESS_KEY_ID,
				secretAccessKey: environment.S3_AWS_SECRET_ACCESS_KEY,
			}),
		});

		this.s3 = new AWS.S3();
	}

	changeMessage(message: string) {
		this.messageSource.next(message);
	}
	setDistributionsTrigger(value: { status: string; distributions: Distributions }) {
		this.distributionsTrigger$.next(value);
	}
	getDistributionsTrigger() {
		return this.distributionsTrigger$;
	}
	getDashboardDrivers(postjson) {
		return this.http.post(`${environment.getdriverstats}`, postjson);
	}

	getRentalList(driver_id: string) {
		return this.http.get(`http://localhost:4000/rentals/${driver_id}`);
	}

	async getRentalListBetween(driver_id: string, from: string, to: string) {
		const res = await this.http
			.get<any>(`${environment.getRentalListBetweenUrl}/${driver_id}?from=${from}&to=${to}`)
			.toPromise();
		return res;
	}

	getDrivingPerformance(driver_id: string, from: string, to: string) {
		return this.http.get(
			`${environment.getDrivingPerformanceUrl}/${driver_id}?from=${from}&to=${to}`
		);
	}
	async sendEventConfirm(rowData: any) {
		const res = await this.http
			.get<any>(
				`${environment.sendEventConfirmUrl}/${rowData.device_id}?driver_id=${rowData.driver_id}&time_stamp=${rowData.time_stamp}`
			)
			.toPromise();
		return res;
	}

	getCameraIds(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.cameraIdsQuery = this.apollo.watchQuery<Company>({
			query: GET_CAMERA_IDS_QUERY,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.cameraIdsQuery.valueChanges.pipe(delay(100));
	}

	async getCompanyStats(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		const companyQuery = this.apollo
			.query<Company>({
				query: DASHBOARD_QUERY,
				variables: {
					start_date: getStartDate,
					end_date: getEndDate,
				},
				fetchPolicy: 'network-only',
			})
			.toPromise();
		return ((await companyQuery).data as any).getCompany;
	}

	getDashBoard(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.dashboardQuery = this.apollo.watchQuery<Company>({
			query: DASHBOARD_QUERY,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.dashboardQuery.valueChanges.pipe(delay(100));
	}

	getStatsPerDay(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.dashboardQuery = this.apollo.watchQuery<Company>({
			query: DASHBOARD_GRAPHS_STATS_PER_DAY,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.dashboardQuery.valueChanges.pipe(delay(100));
	}
	getBottomDrivers(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.dashboardQuery = this.apollo.watchQuery<Company>({
			query: DASHBOARD_QUERY_BOTTOM_DRIVERS,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.dashboardQuery.valueChanges.pipe(delay(100));
	}

	getDashBoardGraphs(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.dashboardQuery = this.apollo.watchQuery<Company>({
			query: DASHBOARD_GRAPHS_QUERY,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.dashboardQuery.valueChanges.pipe(delay(100));
	}

	getDriversList(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		let driverListByCompanyId_Date = {
			email: localStorage.getItem('email'),
			start_date: getStartDate,
			end_date: getEndDate,
		};

		return this.http.post(`${environment.getdriverlist}/driverstats`, driverListByCompanyId_Date);
	}

	async getCompanyDriversList() {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArry: Promise<object>[] = [];
		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArry.push(
					this.http
						.get<any>(
							`${environment.driverAllList}/${localStorage.getItem('sub_company_id' + index)}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArry.push(
				this.http
					.get<any>(`${environment.driverAllList}/${localStorage.getItem('company_id')}`)
					.toPromise()
			);
		}
		const res = Promise.all(promiseArry).then((list) => {
			let totalList = [];
			let totalCount = 0;
			list.forEach((value) => {
				totalList = totalList.concat(value['driver_list']);
				totalCount += Number(value['total_count']);
			});
			return { driver_list: totalList, total_count: totalCount };
		});

		return await res;
	}

	async getMonthlyCompanyDriversList(date_key: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArry: Promise<object>[] = [];
		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArry.push(
					this.http
						.get<any>(
							`${environment.driverAllList}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArry.push(
				this.http
					.get<any>(
						`${environment.driverAllList}/${localStorage.getItem(
							'company_id'
						)}?date_key=${date_key}`
					)
					.toPromise()
			);
		}
		const res = Promise.all(promiseArry).then((list) => {
			let totalList = [];
			let totalCount = 0;
			list.forEach((value) => {
				totalList = totalList.concat(value['driver_list']);
				totalCount += Number(value['total_count']);
			});
			return { driver_list: totalList, total_count: totalCount };
		});

		return await res;
	}

	getTripsDriversList(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.tripsDriversListQuery = this.apollo.watchQuery<Company>({
			query: GET_DRIVERS_TRIPS_LIST,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.tripsDriversListQuery.valueChanges.pipe(delay(100));
	}

	getDriversListWithCompanyDrivers(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.driversListWithCompQuery = this.apollo.watchQuery<Company>({
			query: GET_DRIVERS_LIST_WITH_COMPANY_DATA_DRIVER,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.driversListWithCompQuery.valueChanges.pipe(delay(100));
	}
	getDriversListWithCompanyStatsDiff(startDate: string, endDate: string) {
		const getStartDate = dayjs(startDate).format('YYYY-MM-DD 00:00:00');
		const getEndDate = dayjs(endDate).format('YYYY-MM-DD 23:59:59');
		this.driversListWithCompQuery = this.apollo.watchQuery<Company>({
			query: GET_DRIVERS_LIST_WITH_COMPANY_DATA,
			variables: {
				start_date: getStartDate,
				end_date: getEndDate,
			},
		});
		return this.driversListWithCompQuery.valueChanges.pipe(delay(100));
	}

	// 비디오 경로 호출
	getCameraStatusByID(video: any) {
		let camera_id;
		let file_path;
		if (video['camera_id']) {
			camera_id = video.camera_id;
		} else if (video['old_device_id']) {
			camera_id = video.old_device_id;
		} else if (video['device_id']) {
			camera_id = video.device_id;
		} else {
			camera_id = 'id no exist';
		}
		if (video['filepath']) {
			file_path = video.filepath;
		} else if (video['video_file']) {
			file_path = video.video_file;
		} else {
			file_path = 'filePath no exist';
		}

		// 관리자: 원본파일, 고객: 무음파일 재생
		return this.http
			.get<CameraStatus>(`${environment.getVideoUrl}/${camera_id}`, {
				params: {
					file_path: file_path,
					time_stamp: video.time_stamp,
					requester: localStorage.getItem('email'),
				},
			})
			.toPromise();
	}

	getCameraStatusByID2(video: any) {
		let camera_id;
		let file_path;
		if (video['old_device_id']) {
			camera_id = video.old_device_id;
		} else if (video['camera_id']) {
			camera_id = video.camera_id;
		} else if (video['device_id']) {
			camera_id = video.device_id;
		} else {
			camera_id = 'id no exist';
		}
		if (video['filepath']) {
			file_path = video.filepath;
		} else if (video['video_file']) {
			file_path = video.video_file;
		} else {
			file_path = 'filePath no exist';
		}
		// 관리자: 원본파일, 고객: 무음파일 재생
		return this.http.get<CameraStatus>(`${environment.getVideoUrl}/${camera_id}`, {
			params: {
				file_path: file_path,
				time_stamp: video.time_stamp,
				requester: localStorage.getItem('email'),
			},
		});
	}

	// 비디오 요청 (요청 이력관리)
	async requestDeviceVideo(video: any, type: any) {
		// normal or event video 를 요청한다
		var postData = video;
		postData['requester'] = localStorage.getItem('email');
		// type :  normal or event (db가 다름)
		postData['type'] = type;
		// console.log('postData' + postData)

		const res = await this.http.post<any>(environment.requestDeviceVideoUrl, postData).toPromise();
		return res;
	}

	async mypage_myaccount() {
		const url = environment.mypageMyaccountUrl + localStorage.getItem('company_id');
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	async mypage_serviceplan() {
		const url = environment.mypageServiceplanUrl + localStorage.getItem('company_id');
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	async mypage_monthly_fee(month) {
		let date_key;
		if (month == null) {
			date_key = dayjs(new Date()).format('YYYYMM');
		} else {
			date_key = month;
		}

		let email = localStorage.getItem('email');
		// 관리자용 아이디로 로그인시. 고객용 아이디 내용 조회하도록
		email = email.replace('-admin@', '@');
		const url =
			environment.mypageMonthlyFeeUrl +
			localStorage.getItem('company_id') +
			'?manager=' +
			email +
			'&date_key=' +
			date_key;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 회사별 배차리스트
	async dispatch_list() {
		const url = environment.dispatchListUrl + localStorage.getItem('company_id');
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 새로만든 post api 로 대체. 요청이력 관리를 위함.
	async getCameraAlertByID(video: any, type: any) {
		const res = await this.http
			.get(`${environment.getCameraAlertByIDUrl}/${video.device_id}`, {
				params: { file_path: video.video_file, time_stamp: video.time_stamp, type: type },
			})
			.toPromise();
		return res;
	}
	async getAddress(latlon: LatLon | LatLng) {
		const lat = latlon.lat.toFixed(5);
		const lon = ((latlon as LatLon).lon || (latlon as LatLng).lng).toFixed(5);
		const s = `${lat},${lon}`;
		const cached = DataService.addressResultCache.get(s);
		if (cached) {
			return cached;
		}
		const url = `${environment.getAddressUrl2}/${lat}/${lon}`;
		const res = await this.http.get<Address>(url).toPromise();
		DataService.addressResultCache.set(s, res);
		return res;
	}

	async tripapi(cameraId: number, getStartDate: string, getEndDate: string, nextToken?) {
		let device_id = String(cameraId).split('-')[0];
		let start = dayjs(getStartDate).valueOf();
		let end = dayjs(getEndDate).valueOf();

		const url = environment.tripapiUrl + device_id + '?startdt=' + start + '&enddt=' + end;
		const res = await this.http.get<any>(url).toPromise();
		return res.eventList;
	}
	//
	// Trip
	//

	async getTripLatLon(trip: any) {
		// console.log(trip);
		var device_id = String(trip.device_id).split('-')[0];
		const data = {
			camera_id: device_id,
			trip_start: trip.trip_start,
		};
		// .execute-api.us-west-2.amazonaws.com/staging/latlon
		const res = await this.http.post<any>(environment.getTripLatLonUrl, data).toPromise();
		return res;
	}

	async getVideos(cameraId: number, from: String, to: String) {
		const res = await this.http
			.get<{ vdo_list: VideoEntry[] }>(
				`${environment.getAllVideoApiUrl}/${cameraId}?from=${from}&to=${to}`
			)
			.toPromise();
		return res.vdo_list.map<VideoEntry>((v) => ({
			...v,
			time_stamp_date: new Date(v.time_stamp),
			camera_id: +v.camera_id,
		}));
	}

	async getVideosAll(lastTimestamp: string, deviceId: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArry: Promise<any>[] = [];
		const companyId = localStorage.getItem('company_id');
		const andongCompany = 'faaad84a-7e12-46ef-bfad-fa8aa5ee6664';
		const gsCompany = '9f37160f-1e63-42ae-a862-ef28cae3c736';
		const params =
			lastTimestamp !== '' && deviceId !== ''
				? `?last_time_stamp=${lastTimestamp}&device_id=${deviceId}`
				: '';

		const videoTypes = ['event', 'normal'];

		for (const videoType of videoTypes) {
			const videoTypeParam = `${params === '' ? '?' : '&'}video_type=${videoType}`;

			if (!!isSubCompany) {
				if (companyId === andongCompany || companyId === gsCompany) {
					// 안동시청 대시보드 -> 통합계정 company_id로 요청(포트홀 이벤트 영상만 받아옴)
					promiseArry.push(
						this.http
							.get<any>(`${environment.getAllVideoApiUrl2}/${companyId}${params}${videoTypeParam}`)
							.toPromise()
					);
				} else {
					for (let index = 0; index < +isSubCompany; index++) {
						promiseArry.push(
							this.http
								.get<{ vdo_list: any[] }>(
									`${environment.getAllVideoApiUrl2}/${localStorage.getItem(
										'sub_company_id' + index
									)}${params}${videoTypeParam}`
								)
								.toPromise()
						);
					}
				}
			} else {
				promiseArry.push(
					this.http
						.get<{ vdo_list: any[] }>(
							`${environment.getAllVideoApiUrl2}/${companyId}${params}${videoTypeParam}`
						)
						.toPromise()
				);
			}
		}

		const res = await Promise.all(promiseArry).then((list) => {
			let totalList = [];
			list.forEach((value) => {
				totalList = totalList.concat(value['vdo_list']);
			});
			return { vdo_list: totalList };
		});
		return res;
	}

	async getVehicleList(date_key: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArry: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArry.push(
					this.http
						.get<{ data: any }>(
							`${environment.getVehicleList}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArry.push(
				this.http
					.get<{ data: any }>(
						`${environment.getVehicleList}/${localStorage.getItem(
							'company_id'
						)}?date_key=${date_key}`
					)
					.toPromise()
			);
		}
		const res = Promise.all(promiseArry).then((list) => {
			let totalList = [];
			list.forEach((value) => {
				totalList = totalList.concat(value['vehicle_list']);
			});
			return { vehicle_list: totalList };
		});
		return await res;
	}

	async getVehicleRentalList(vehicleId: string) {
		const res = await this.http
			.get<{ data: any }>(`${environment.getVehicleRentalList}/${vehicleId}`)
			.toPromise();
		return res;
	}

	async activeDeviceList(id: string, type?: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const typeData = !!type ? type : '';
		const date = new Date();
		const startDate = dayjs(date.setUTCMinutes(date.getUTCMinutes() - 1)).valueOf();
		const promiseArry: Promise<object>[] = [];
		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArry.push(
					this.http
						.get<{ data: any }>(
							`${environment.activeDeviceList}/${localStorage.getItem(
								'sub_company_id' + index
							)}?time_stamp=${startDate}` + typeData
						)
						.toPromise()
				);
			}
		} else {
			promiseArry.push(
				this.http
					.get<{ data: any }>(
						`${environment.activeDeviceList}/${localStorage.getItem(
							'company_id'
						)}?time_stamp=${startDate}` + typeData
					)
					.toPromise()
			);
		}
		const res = Promise.all(promiseArry).then((list) => {
			let status = '';
			let totalList = [];
			let vehicle_num = [];

			list.forEach((data) => {
				status = data['status'];
				totalList = totalList.concat(data['deviceList']);
				vehicle_num = vehicle_num.concat(data['vehicle_num']);
			});
			return { status, deviceList: totalList, vehicle_num: vehicle_num };
		});
		return await res;
	}

	// DynamoDB 에 company_id, event 조회를 위한 index 생성후
	// API Gateway & Lambda 생성 . 2021.09.13 james.
	async getNotiList(event_type: any, all?: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		let company_ids = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				company_ids.push(localStorage.getItem('sub_company_id' + index));
			}
		} else {
			company_ids.push(localStorage.getItem('company_id'));
		}
		let url = `${environment.getNotiListUrl}/${company_ids}?event=${event_type}`;

		url += all !== '' ? `&all=${all}` : '';

		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	async getRecentTrips(driver_id: string, queryParams: string) {
		let url = environment.getRecentTripsUrl + driver_id + '?' + queryParams;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	async overviewAvg() {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const date = new Date();
		const dateFormat = dayjs(date).utc().subtract(5, 'month').format('YYYYMM');

		const emailToLocationMap: { [key: string]: string } = {
			'koroad-gimpo@carvi.co.kr': 'gimpo',
			'koroad-gwangju@carvi.co.kr': 'gwangju',
			'koroad-daegu@carvi.co.kr': 'daegu',
			'koroad-changwon@carvi.co.kr': 'changwon',
		};
		const location = emailToLocationMap[localStorage.getItem('email')] || null;

		const createUrl = (id: string) => {
			let url = `${environment.overviewAvgUrl}/${id}?date_key=${dateFormat}`;
			if (location) {
				url += `&location=${location}`;
			}
			return url;
		};

		let cumulList = [];
		let overviewStatMonthly = [];

		const fetchData = async (id: string) => {
			try {
				const response = await this.http.get<any>(createUrl(id)).toPromise();
				return response;
			} catch (error) {
				console.error(error);
				return null;
			}
		};

		if (isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				const subCompanyId = localStorage.getItem('sub_company_id' + index);
				if (subCompanyId) {
					const data = await fetchData(subCompanyId);
					if (data) {
						cumulList = cumulList.concat(data['cumul_stat']);
						overviewStatMonthly = overviewStatMonthly.concat(data['overview_stat_monthly']);
					}
				}
			}
		} else {
			const companyId = localStorage.getItem('company_id');
			if (companyId) {
				const data = await fetchData(companyId);
				if (data) {
					cumulList = cumulList.concat(data['cumul_stat']);
					overviewStatMonthly = overviewStatMonthly.concat(data['overview_stat_monthly']);
				}
			}
		}

		if (cumulList.length === 0 && overviewStatMonthly.length === 0) {
			throw new Error('no data');
		}

		return {
			cumul_stat: cumulList,
			overview_stat_monthly: overviewStatMonthly,
		};
	}

	async getDriverInfo(driver_id: string) {
		const res = await this.http.get<any>(`${environment.getDriverInfo}/${driver_id}`).toPromise();
		return res;
	}
	async getDriverInfoEx(driver_id: string, queryParam: string) {
		const res = await this.http
			.get<any>(`${environment.getDriverInfo}/${driver_id}?${queryParam}`)
			.toPromise();
		return res;
	}
	async getVehicleInfo(device_id: string, queryParam: string) {
		const res = await this.http
			.get<any>(`${environment.reportApi}/vehicle/profile/${device_id}?${queryParam}`)
			.toPromise();
		return res;
	}
	async getReport(type: string, pathParam: string, queryParam: string) {
		pathParam = encodeURI(pathParam);
		const res = await this.http
			.get<any>(`${environment.reportApi}/${type}/${pathParam}?${queryParam}`)
			.toPromise();
		return res;
	}

	async getTrips(pathParam: string, queryParam: string) {
		const res = await this.http
			.get<any>(`${environment.trips}/${pathParam}?${queryParam}`)
			.toPromise();
		return res;
	}

	async tripList(startDate: string, endDate: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArry: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArry.push(
					this.http
						.get<any>(
							`${environment.tripList}/${localStorage.getItem(
								'sub_company_id' + index
							)}?start_date=${startDate}&end_date=${endDate}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArry.push(
				this.http
					.get<any>(
						`${environment.tripList}/${localStorage.getItem(
							'company_id'
						)}?start_date=${startDate}&end_date=${endDate}`
					)
					.toPromise()
			);
		}

		//event list (smoke, parking)
		const res = Promise.all(promiseArry).then((list) => {
			let totalList = [];
			list.forEach((value) => {
				totalList = totalList.concat(value['alltrips_list']);
			});
			return { alltrips_list: totalList };
		});
		return await res;
	}

	async getVehicleSearch(searchValue: any) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArry: Promise<object>[] = [];
		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArry.push(
					this.http
						.get<any>(
							`${environment.getVehicleSearch}/all?company_id=${localStorage.getItem(
								'sub_company_id' + index
							)}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArry.push(
				this.http
					.get<any>(
						`${environment.getVehicleSearch}/all?company_id=${localStorage.getItem('company_id')}`
					)
					.toPromise()
			);
		}

		const res = Promise.all(promiseArry).then((list) => {
			let totalList = [];
			list.forEach((value) => {
				totalList = totalList.concat(value['list']);
			});
			return { list: totalList };
		});
		return await res;
	}

	async getNormalVideos(
		cameraId: number,
		from: string,
		to: string,
		lastTimeStamp: string,
		limit: number,
		company_id: string,
		morevideo?: string
	) {
		// morevideo 이벤트 영상 포함한 리스트
		const queryString = `from=${from}&to=${to}&lastTimeStamp=${lastTimeStamp}${
			limit ? '&limit=' + limit : ''
		}&company_id=${company_id}&morevideo=${morevideo}`;

		const url = `${environment.getAllVideoApiUrl}/${cameraId}?${queryString}`;

		try {
			const res = await this.http.get<any>(url).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async notiConfirm(notiInfo: any) {
		const data = {
			device_id: notiInfo.device_id,
			time_stamp: notiInfo.time_stamp,
		};
		// .execute-api.us-west-2.amazonaws.com/staging/latlon
		const res = await this.http.post<TripDetails>(environment.notiConfirmUrl, data).toPromise();
		return res;
	}

	getDistributions(score: riskFactorItemScore) {
		return this.http.post(environment.getDistributionsUrl, score);
	}

	async crashRemove(eventInfo: any) {
		const data = {
			device_id: eventInfo.device_id,
			time_stamp: eventInfo.time_stamp,
		};
		const res = await this.http.post<TripDetails>(environment.crashRemoveUrl, data).toPromise();
		return res;
	}

	// 근무기록 리스트
	async getDispatchList(company_id: string, date_key: string, driver_name?: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					await this.http
						.get<any>(
							`${environment.getDispatchListUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}&driver_name=${driver_name}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				await this.http
					.get<any>(
						`${environment.getDispatchListUrl}/${company_id}?date_key=${date_key}&driver_name=${driver_name}`
					)
					.toPromise()
			);
		}

		const res = await Promise.all(promiseArr).then((list) => {
			let date_key = '';
			let dispatch_list = [];
			let month_days: number;
			let qr_day_max = [];
			let status = '';

			list.forEach((value) => {
				date_key = value['date_key'];
				dispatch_list = dispatch_list.concat(value['dispatch_list']);
				month_days = value['month_days'];
				qr_day_max = value['qr_day_max'];
				status = value['status'];
			});
			return { date_key, dispatch_list, month_days, qr_day_max, status };
		});

		return res;
	}

	// 근무기록(운전자 리스트)
	async getDriverList(company_id: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					await this.http
						.get<any>(
							`${environment.getDriverListUrl}/${localStorage.getItem('sub_company_id' + index)}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				await this.http.get<any>(`${environment.getDriverListUrl}/${company_id}`).toPromise()
			);
		}

		const res = await Promise.all(promiseArr).then((list) => {
			let drivers = [];
			let status = '';

			list.forEach((value) => {
				drivers = drivers.concat(value['drivers']);
				status = value['status'];
			});

			return { drivers, status };
		});

		return res;
	}

	// 리포트 운전자(월별)
	async getReportMonthlyData(driver_id: string, date_key: string) {
		const cacheKey = `reportData_${driver_id}_${date_key}`;
		const cacheData = await this.indexedDBService.get(cacheKey);
		if (cacheData) {
			return of(cacheData);
		}

		try {
			const res = await this.http
				.get<any>(`${environment.getReportMonthlyDataUrl}/${driver_id}?date_key=${date_key}`)
				.toPromise();

			await this.indexedDBService.set(cacheKey, {
				comment: res['comment'],
				monthly_data: res['monthly_data'],
				prev_data: res['prev_data'],
				daily_data: res['daily_data'],
				status: res['status'],
				vehicle_no: res['vehicle_no'],
			});

			return res;
		} catch (err) {
			console.error(err);
			return of(null);
		}
	}

	// 리포트 기관(월별)
	async getCompanyReportMonthlyData(company_id: string, date_key: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					this.http
						.get<any>(
							`${environment.getCompanyReportMonthlyDataUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				this.http
					.get<any>(
						`${environment.getCompanyReportMonthlyDataUrl}/${company_id}?date_key=${date_key}`
					)
					.toPromise()
			);
		}

		const res = Promise.all(promiseArr).then((list) => {
			let driver_num: number;
			let monthly_data: any;
			let prev_data: any;
			let status: string;

			list.forEach((value) => {
				driver_num = value['driver_num'];
				monthly_data = value['monthly_data'];
				prev_data = value['prev_data'];
				status = value['status'];
			});
			return {
				driver_num: driver_num,
				monthly_data: monthly_data,
				prev_data: prev_data,
				status: status,
			};
		});
		return res;
	}

	// 리포트 표지용 담당자 정보(리포트 생성)
	async getReportInfo(company_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getReportInfoUrl}/${company_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	// 리포트 운전자 리스트(리포트 생성)
	async getReportDriverList(company_id: string, date_key?: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					this.http
						.get<any>(
							`${environment.getReportDriverListUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				this.http
					.get<any>(`${environment.getReportDriverListUrl}/${company_id}?date_key=${date_key}`)
					.toPromise()
			);
		}

		const res = Promise.all(promiseArr).then((list) => {
			let data = [];
			let status = '';
			let last_updated_month = null;
			list.forEach((value) => {
				data = data.concat(value['drivers']);
				status = value['status'];
				last_updated_month = value['last_updated_month'];
			});
			return { drivers: data, status: status, last_updated_month: last_updated_month };
		});
		return await res;
	}

	// 리포트 동종업계 hec
	async getIndustryReport(industry_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getIndustryReportUrl}/${industry_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	// 교통사고 요약 리포트
	async getCrashDetectedData(company_id: string, event: string, date_key: string) {
		const res = await this.http
			.get<any>(
				`${environment.getCrashDetectedDataUrl}/${company_id}?event=${event}&date_key=${date_key}`
			)
			.toPromise();

		return res;
	}

	// 다운로드 URL
	async downloadReportFile(company_id: string, date_key: string, email: string, garage?: string) {
		let url = `${environment.downloadReportFileUrl}/${company_id}?date_key=${date_key}&username=${email}`;

		if (garage) {
			url += `&garage=${garage}`;
		}

		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 월별 리포트 리스트 조회(리포트 생성)
	async downloadReportList(company_id: string, date_key: string) {
		const isSubCompany = Number(localStorage.getItem('sub_company_id_length'));
		const promiseArr: Promise<any>[] = [];

		if (isSubCompany > 0) {
			for (let index = 0; index < isSubCompany; index++) {
				const subCompanyId = localStorage.getItem(`sub_company_id${index}`);
				if (subCompanyId) {
					promiseArr.push(
						this.http
							.get<any>(`${environment.downloadReportListUrl}/${subCompanyId}?date_key=${date_key}`)
							.toPromise()
					);
				}
			}
		} else {
			promiseArr.push(
				this.http
					.get<any>(`${environment.downloadReportListUrl}/${company_id}?date_key=${date_key}`)
					.toPromise()
			);
		}

		try {
			const list = await Promise.all(promiseArr);

			let monthList = [];
			let yearList = [];
			const yearMonthList = list[0]['year_month_list'];
			const status = list[0]['status'];

			list.forEach((value) => {
				monthList = monthList.concat(value['month_list']);
				yearList = yearList.concat(value['year_list']);
			});

			const uniqueMonthList = monthList.filter(
				(val, idx, self) => idx === self.findIndex((v) => v === val)
			);
			const uniqueYearList = yearList.filter(
				(val, idx, self) => idx === self.findIndex((v) => v === val)
			);

			return {
				month_list: uniqueMonthList,
				year_list: uniqueYearList,
				year_month_list: yearMonthList,
				status: status,
			};
		} catch (error) {
			console.error(error);
		}
	}

	// 연비리포트 기관 월별
	async getCompanyMonthlyData(driver_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getCompanyMonthlyDataUrl}/${driver_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	// 연비리포트 기관 일별
	async getCompanyDailyData(driver_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getCompanyDailyDataUrl}/${driver_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	// 연비리포트 운전자 월별
	async getDriverMonthlyData(driver_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getDriverMonthlyDataUrl}/${driver_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	// 연비리포트 운전자 일별
	async getDriverDailyData(driver_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getDriverDailyDataUrl}/${driver_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	async getCompanyDriverList(company_id: string, date_key?: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					this.http
						.get<any>(
							`${environment.getCompanyDriverListUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				this.http
					.get<any>(`${environment.getCompanyDriverListUrl}/${company_id}?date_key=${date_key}`)
					.toPromise()
			);
		}

		const res = Promise.all(promiseArr).then((list) => {
			// console.log(list);
			let data = [];
			let status = '';
			list.forEach((value) => {
				data = data.concat(value['drivers']);
				status = value['status'];
			});
			return { drivers: data, status };
		});
		return await res;
	}

	// 운전자 상세 - 일별 -> from_dt: 20230305, to_dt: 20230310
	async getDailyDriverData(driver_id: string, from_dt: string, to_dt: string) {
		const res = await this.http
			.get<any>(
				`${environment.getDailyDriverDataUrl}/${driver_id}?from_dt=${from_dt}&to_dt=${to_dt}`
			)
			.toPromise();

		return res;
	}

	// 운전자 상세 - 운행기록(20230601, 20230630, all)
	async getDriverRentalList(driver_id: string, from_dt: string, to_dt: string, all: string) {
		const res = await this.http
			.get<any>(
				`${environment.getDriverRentalListUrl}/${driver_id}?from_dt=${from_dt}&to_dt=${to_dt}&all=${all}`
			)
			.toPromise();

		return res;
	}

	// 리포트 실시간 운전자, 운전자 상세 프로필
	// 운전자 상세 - 월별 -> from_dt: 202301, to_dt: 202303
	async getMonthlyDriverData(driver_id: string, from_dt: string, to_dt: string) {
		const res = await this.http
			.get<any>(
				`${environment.getMonthlyDriverDataUrl}/${driver_id}?from_dt=${from_dt}&to_dt=${to_dt}`
			)
			.toPromise();
		return res;
	}

	// 리포트 실시간 운전자에서 사용
	async getDriverCumulativData(driver_id: string) {
		const res = await this.http
			.get<any>(`${environment.getDriverCumulativDataUrl}/${driver_id}`)
			.toPromise();
		return res;
	}

	// 운전자별 주행 조회
	async getRangeTrips(driver_id: string, from_dt: string, to_dt: string) {
		const res = await this.http
			.get<any>(`${environment.getRangeTripsUrl}/${driver_id}?from_dt=${from_dt}&to_dt=${to_dt}`)
			.toPromise();

		return res;
	}

	// 차량별 주행 조회
	async getRangeTripsOld(driver_id: string, from_dt: string, to_dt: string) {
		const res = await this.http
			.get<any>(`${environment.getRangeTripsOldUrl}/${driver_id}?from_dt=${from_dt}&to_dt=${to_dt}`)
			.toPromise();

		return res;
	}

	// 리포트 실시간(운전자 주행현황 차트)
	async getDriverMileageData(driver_id: string, date_key?: string) {
		const res = await this.http
			.get<any>(`${environment.getDriverMileageDataUrl}/${driver_id}?date_key=${date_key}`)
			.toPromise();
		return res;
	}

	// 리포트 실시간(운전자 연비퍼포먼스)
	async getDriverRecentTrips(driver_id: string, date_key: string, matched: string) {
		const res = await this.http
			.get<any>(
				`${environment.getDriverRecentTripsUrl}/${driver_id}?date_key=${date_key}&matched=${matched}`
			)
			.toPromise();
		return res;
	}

	// 리포트 실시간 (월별_운전자 운전퍼포먼스)
	async getDriverRiskList(driver_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.getDriverRiskListUrl}/${driver_id}?date_key=${date_key}`)
			.toPromise();
		return res;
	}
	// 리포트 실시간(누적_운전자 운전퍼포먼스)
	async getCumulRiskList(driver_id: string) {
		const res = await this.http
			.get<any>(`${environment.getCumulRiskListUrl}/${driver_id}`)
			.toPromise();
		return res;
	}

	// 리포트 운전자 이벤트항목 리스트(월별)
	async getDriverEventList(driver_id: string, date_key: string, event: string) {
		const res = await this.http
			.get<any>(
				`${environment.getDriverEventListUrl}/${driver_id}?date_key=${date_key}&event=${event}`
			)
			.toPromise();
		return res;
	}
	// 이벤트항목 리스트(누적)
	async getCumulEventList(driver_id: string, event: string) {
		const res = await this.http
			.get<any>(`${environment.getCumulEventListUrl}/${driver_id}?event=${event}`)
			.toPromise();
		return res;
	}

	async getDriverStat(pathParam: string, queryParam: string) {
		pathParam = encodeURI(pathParam);
		// pathParam = pathParam.split('-')[0]
		const url = environment.getDriverStatUrl;
		const res = await this.http.get<any>(`${url}/${pathParam}?${queryParam}`).toPromise();
		return res;
	}

	// 기관 누적 데이터 점수
	async getCompanyCumulData(company_id: string, date_key: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<any>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					this.http
						.get<any>(
							`${environment.getCompanyCumulDataUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}?date_key=${date_key}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				this.http
					.get<any>(`${environment.getCompanyCumulDataUrl}/${company_id}?date_key=${date_key}`)
					.toPromise()
			);
		}

		const res = Promise.all(promiseArr).then((list) => {
			if (!!isSubCompany) {
				let data = [];
				let status = '';

				list.forEach((value) => {
					data = data.concat(value['company_skor']);
					status = value['status'];
				});

				return {
					company_skor: data,
					status: status,
				};
			} else {
				let data: any;
				let status = '';

				list.forEach((value) => {
					data = value['company_skor'];
					status = value['status'];
				});

				return {
					company_skor: data,
					status: status,
				};
			}
		});
		return await res;
	}

	// 주간 공회전 현황
	async getIdleList(company_id: string, datetime: string) {
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					this.http
						.get<{ data: any }>(
							`${environment.getIdleListUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}?datetime=${datetime}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(
				this.http
					.get<{ data: any }>(`${environment.getIdleListUrl}/${company_id}?datetime=${datetime}`)
					.toPromise()
			);
		}

		const res = Promise.all(promiseArr).then((list) => {
			let idle_list = [];
			let status: string;

			list.forEach((value) => {
				idle_list = idle_list.concat(value['idle_list']);
				status = value['status'];
			});
			return { status: status, idle_list: idle_list };
		});
		return await res;
	}

	// 공회전 연락 상태 전환
	async setNotiStatus(device_id: string, datetime: string) {
		// datetime 2023-08-28 15:42:45.391
		const res = await this.http
			.get<any>(`${environment.setNotiStatusUrl}/${device_id}?datetime=${datetime}`)
			.toPromise();

		return res;
	}

	// 공회전 문자 전송
	async sendSMS(device_id: string, datetime: string) {
		// datetime 2023-08-04 11:12:11.358
		const res = await this.http
			.get<any>(`${environment.sendSMSUrl}/${device_id}?datetime=${datetime}`)
			.toPromise();

		return res;
	}

	// 공회전 내역 다운로드
	async downloadIdleReports(company_id: string, date_key: string) {
		const res = await this.http
			.get<any>(`${environment.downloadIdleReportsUrl}/${company_id}?date_key=${date_key}`)
			.toPromise();

		return res;
	}

	// 동별 도로명 가져오기 (date_key 버전명을 의미 ex)20231013 -> 2023년 10월 13일 디비)
	async getDongRoad(date_key: string) {
		const url = `${environment.getDongRoadUrl}/${date_key}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 동전체 좌표 찾기
	async getDongBasicCode(code: any, dateKey?: string) {
		const cacheExpirationTime = 24 * 60 * 60 * 1000;

		const cacheKey = `dongBasicCode_${code}`;
		const cacheData = await this.indexedDBService.get(cacheKey);
		if (cacheData && cacheData['expirationTime'] > Date.now()) {
			return of(cacheData);
		}

		try {
			let url = `${environment.getDongBasicCodeUrl}/${code}`;

			if (dateKey) {
				url += `?date_key=${dateKey}`;
			}

			const res = await this.http.get<any>(url).toPromise();
			const expirationTime = Date.now() + cacheExpirationTime;

			await this.indexedDBService.set(cacheKey, {
				basic_codes: res['basic_codes'],
				status: res['status'],
				expirationTime,
			});

			return res;
		} catch (err) {
			console.error(err);
			return of(null);
		}
	}

	// 포트홀 이벤트 (Pothole Detected)
	async getRoadEvent(event_type: string, road_code?: number | string, emd_code?: number | string) {
		const cacheExpirationTime = 24 * 60 * 60 * 1000;

		const cacheKey =
			`roadEvent_${event_type}` +
			`${road_code && emd_code ? '_road_code=' + road_code + '_emd_code=' + emd_code : ''}`;
		const cacheData = await this.indexedDBService.get(cacheKey);
		if (cacheData && cacheData['expirationTime'] > Date.now()) {
			return of(cacheData);
		}

		try {
			let url = `${environment.getRoadEventUrl}/${event_type}`;

			if (road_code && emd_code) {
				url += `?road_code=${road_code}&emd_code=${emd_code}`;
			}

			const res = await this.http.get<any>(url).toPromise();
			const expirationTime = Date.now() + cacheExpirationTime;

			await this.indexedDBService.set(cacheKey, {
				daily_count: res['daily_count'],
				road_event: res['road_event'],
				status: res['status'],
				expirationTime,
			});

			return res;
		} catch (err) {
			console.error(err);
			return of(null);
		}
	}

	// 도로 모니터링 리스트
	async getBasicRiskList(basic_id: string | number, closed?: string, cached?: boolean) {
		const url = `${environment.getBasicRiskListUrl}/${basic_id}?closed=${closed}`;

		if (cached) {
			const cacheExpirationTime = 24 * 60 * 60 * 1000;
			const cacheKey = `overviewPotholeDetected`;
			const cacheData = await this.indexedDBService.get(cacheKey);
			if (cacheData && cacheData['expirationTime'] > Date.now()) {
				return of(cacheData);
			}

			try {
				const res = await this.http.get<any>(url).toPromise();
				const expirationTime = Date.now() + cacheExpirationTime;
				await this.indexedDBService.set(cacheKey, {
					status: res['status'],
					risk_list: res['risk_list'],
					expirationTime,
				});

				return res;
			} catch (err) {
				console.error(err);
			}
		} else {
			const res = await this.http.get<any>(url).toPromise();
			return res;
		}
	}

	// 기초정보 상태 업데이트
	async updateBasicStatus(body: any) {
		try {
			const url = environment.updateBasicStatusUrl;
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	// 도로 모니터링 메모
	async createMemo(body: any) {
		try {
			const url = environment.createMemoUrl;
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async updateMemo(body: any) {
		try {
			const url = environment.updateMemoUrl;
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async deleteMemo(body: any) {
		const url = environment.deleteMemoUrl;

		try {
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	// 전체 기초구간 정보 조회
	async getBasicSkorList(date_key: any) {
		const cacheExpirationTime = 24 * 60 * 60 * 1000;
		const cacheKey = `basicSkorList_${date_key}`;
		const cacheData = await this.indexedDBService.get(cacheKey);

		if (cacheData && cacheData['expirationTime'] > Date.now()) {
			return of(cacheData);
		}

		try {
			const url = `${environment.getBasicSkorListUrl}/${date_key}`;
			const res = await this.http.get<any>(url).toPromise();
			const expirationTime = Date.now() + cacheExpirationTime;

			await this.indexedDBService.set(cacheKey, {
				basic_skor_list: res['basic_skor_list'],
				status: res['status'],
				expirationTime,
			});

			return res;
		} catch (err) {
			console.error(err);
			return of(null);
		}

		// 캐싱 x
		// const url = `${environment.getBasicSkorListUrl}/${date_key}`;

		// try {
		// 	const res = await this.http.get<any>(url).toPromise();
		// 	return res;
		// } catch (err) {
		// 	console.error(err);
		// }
	}

	// 도로관리 메모
	async roadInfoCreateMemo(body: any) {
		try {
			const url = environment.roadInfoCreateMemoUrl;
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async roadInfoUpdateMemo(body: any) {
		try {
			const url = environment.roadInfoUpdateMemoUrl;
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async roadInfoDeleteMemo(body: any) {
		try {
			const url = environment.roadInfoDeleteMemoUrl;
			const res = await this.http.post(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	// 전체 기초구간 하위 100개
	async getLimitBasicSkorList(date_key: string, limit: number) {
		const url = `${environment.getLimitBasicSkorListUrl}/${date_key}?limit=${limit}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 읍면동 기초구간 하위 100개
	async getLimitEmdList(date_key: string, limit: number, EUPMYEONDONG_CODE: number) {
		const url = `${environment.getLimitEmdListUrl}/${date_key}?limit=${limit}&EUPMYEONDONG_CODE=${EUPMYEONDONG_CODE}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 구간관리 메모
	async basicRoadCreateMemo(body: any) {
		try {
			const url = environment.basicRoadCreateMemoUrl;
			const res = await this.http.post<any>(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async basicRoadUpdateMemo(body: any) {
		try {
			const url = environment.basicRoadUpdateMemoUrl;
			const res = await this.http.post<any>(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async basicRoadDeleteMemo(body: any) {
		try {
			const url = environment.basicRoadDeleteMemoUrl;
			const res = await this.http.post<any>(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	// 로드 데이터 버전 정보
	async getRoadDataVersion(company_id: string) {
		const cacheExpirationTime = 24 * 60 * 60 * 1000;
		const cacheKey = `roadDataVersion${company_id}`;
		const cacheData = await this.indexedDBService.get(cacheKey);
		if (cacheData && cacheData['expirationTime'] > Date.now()) {
			return of(cacheData);
		}

		try {
			const url = `${environment.getRoadDataVersionUrl}/${company_id}`;
			const res = await this.http.get<any>(url).toPromise();
			const expirationTime = Date.now() + cacheExpirationTime;
			await this.indexedDBService.set(cacheKey, {
				data: res['data'],
				quarterly_risk_ratio: res['quarterly_risk_ratio'],
				status: res['status'],
				expirationTime,
			});

			return res;
		} catch (err) {
			console.error(err);
			return of(null);
		}

		// const url = `${environment.getRoadDataVersionUrl}/${company_id}`;

		// const res = await this.http.get<any>(url).toPromise();
		// return res;
	}

	// 안동시청 road_score 있는 기초구간 표출 - pdf test
	async setAndongRoadData(date_key: string, limit?: number) {
		const cacheExpirationTime = 24 * 60 * 60 * 1000;

		const cacheKey = `andongRoadData_${date_key}`;
		const cacheData = await this.indexedDBService.get(cacheKey);
		if (cacheData && cacheData['expirationTime'] > Date.now()) {
			return of(cacheData);
		}

		try {
			const url = `${environment.setAndongRoadDataUrl}/${date_key}`;
			const res = await this.http.get<any>(url).toPromise();
			const expirationTime = Date.now() + cacheExpirationTime;

			await this.indexedDBService.set(cacheKey, {
				basic_skor_list: res['basic_skor_list'],
				status: res['status'],
				expirationTime,
			});

			return res;
		} catch (err) {
			console.error(err);
			return of(null);
		}
	}

	async getCarviIssueList() {
		const url = environment.getCarviIssueListUrl;

		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// aws s3 file upload
	async uploadDispatchFile(dateKey: string, file: any) {
		const companyId = localStorage.getItem('company_id');
		const formData = new FormData();
		formData.append('file', file);

		// ex) 파일명: 케이원 모빌리티-경진여객 (2) -> (2) 제거
		const regex = /\s*\(\d+\)/g;

		const params: AWS.S3.PutObjectRequest = {
			Bucket: environment.S3_AWS_BUCKET_NAME,
			Key: `${companyId}/${dateKey.slice(0, 6)}/${file.name.replace(regex, '')}`,
			Body: file,
		};

		return new Promise((resolve, reject) => {
			this.s3.upload(params, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(true);
				}
			});
		});
	}

	// aws s3 file download
	async downloadS3File(companyId: string, dateKey: string, companyName: string, fileType: string) {
		console.log(fileType);
		/**
		 * <fileType>
		 * dispatch 주행이력 다운로드
		 * upload 배차정보 확인
		 */

		try {
			const params: AWS.S3.GetObjectRequest = {
				Bucket: environment.S3_AWS_BUCKET_NAME,
				Key:
					fileType === 'upload'
						? `${companyId}/${dateKey.slice(0, 6)}/${companyName}_${dateKey}.xlsx`
						: `${companyId}/${dateKey.slice(0, 6)}/${dateKey}.xlsx`,
			};

			const data = await this.s3.getObject(params).promise();

			// ArrayBuffer로 변환, ArrayBuffer를 Blob으로 변환
			const arrayBuffer = data.Body as ArrayBuffer;
			const blob = new Blob([arrayBuffer], { type: data.ContentType });

			// 파일 다운로드를 위한 URL 생성
			const downloadUrl = window.URL.createObjectURL(blob);

			// a 태그를 사용하여 파일 다운로드
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.download = `${companyName}/${dateKey}.xlsx`;
			document.body.appendChild(link);
			link.click();

			// 메모리에서 URL 객체 제거
			window.URL.revokeObjectURL(downloadUrl);
		} catch (err) {
			console.error(`${fileType} error  `, err);
			this.alertService.alertDialog('관리자에게 문의해 주세요.', '확인', '6').afterClosed();
		}
	}

	// 배차파일 리스트
	async dispatchFileList(companyId: string) {
		const url = `${environment.dispatchFileListUrl}/${companyId}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 등록된 운전자 리스트
	async getRegisteredDriverList(companyId: string, expired?: string) {
		const url = `${environment.getRegisteredDriverListUrl}/${companyId}${
			expired ? '?expired=true' : ''
		}`;
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					await this.http
						.get<any>(
							`${environment.getRegisteredDriverListUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}${expired ? '?expired=true' : ''}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(await this.http.get<any>(url).toPromise());
		}

		const res = await Promise.all(promiseArr).then((list) => {
			let status = '';
			let driver_list = [];

			list.forEach((value) => {
				driver_list = driver_list.concat(value['driver_list']);
				status = value['status'];
			});
			return { status, driver_list };
		});

		return res;
	}

	// 운전자 등록
	async addDriverList(body: any) {
		try {
			const url = environment.addDriverListUrl;
			const res = await this.http.post<any>(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	// 운전자 재입사, 퇴사처리
	async updateDriverStatus(body: any) {
		try {
			const url = environment.deleteDriversUrl;
			const res = await this.http.post<any>(url, body).toPromise();
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	// 기관 차고지 정보
	async getGarageInfo(companyId: string) {
		const url = `${environment.getGarageInfoUrl}/${companyId}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 차량 정보 리스트
	async getVehicleHistory(companyId: string) {
		const url = `${environment.getVehicleHistoryUrl}/${companyId}`;
		const isSubCompany = localStorage.getItem('sub_company_id_length');
		const promiseArr: Promise<object>[] = [];

		if (!!isSubCompany) {
			for (let index = 0; index < +isSubCompany; index++) {
				promiseArr.push(
					await this.http
						.get<any>(
							`${environment.getVehicleHistoryUrl}/${localStorage.getItem(
								'sub_company_id' + index
							)}`
						)
						.toPromise()
				);
			}
		} else {
			promiseArr.push(await this.http.get<any>(url).toPromise());
		}

		const res = await Promise.all(promiseArr).then((list) => {
			let vehicleList = [];

			list.forEach((value) => {
				vehicleList = vehicleList.concat(value);
			});
			return { vehicleList };
		});

		return res;
	}

	// 차량 정보 업데이트
	async updateVehicleInfo(body: any) {
		const url = environment.updateVehicleInfoUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 탈거요청 remove, 탈거취소 cancel, 이슈등록 voc
	async requestCompanyAction(body: any) {
		const url = environment.requestCompanyActionUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 이슈 현황 리스트
	async getVocList(companyId: string) {
		const url = `${environment.getVocListUrl}/${companyId}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 이슈 상태 업데이트
	async updateCompanyAction(body: any) {
		const url = environment.updateCompanyActionUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 민원 항목(메모)
	async updateComplaintMemo(body: any) {
		const url = environment.updateComplaintMemoUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 민원 항목(상태)
	async updateComplaintStatus(body: any) {
		const url = environment.updateComplaintStatusUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 민원 등록
	async createComplaint(body: any) {
		const url = environment.createComplaintUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 민원 처리 등록
	async updateMwProcessInfo(body: any) {
		const url = environment.updateMwProcessInfoUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 스쿨존 이벤트
	async getSchoolZoneEventList(companyId: string, dateKey?: string) {
		let url = `${environment.getSchoolZoneEventListUrl}/${companyId}`;

		if (dateKey) {
			url += `?date_key=${dateKey}`;
		}

		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 스쿨존 이벤트 전환(관리자용)
	async updateSchoolZoneEvent(deviceId: string, timeStamp: string) {
		const url = `${environment.updateSchoolZoneEventUrl}/${deviceId}?time_stamp=${timeStamp}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 공지 리스트
	async getNoticeList(companyId: string) {
		const url = `${environment.getNoticeListUrl}/${companyId}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 공지 생성
	async createNotice(body: any) {
		const url = environment.createNoticeUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 게시글 파일 첨부
	async postFileAttachment(file: File, date: string, fileName: string, companyId: string) {
		const encodedFileName = encodeURIComponent(fileName);
		const decodedFileName = decodeURIComponent(encodedFileName);

		const contentDispositionFileName = Buffer.from(decodeURIComponent(file.name), 'utf-8').toString(
			'base64'
		);
		const contentDisposition = `attachment; filename*=UTF-8${contentDispositionFileName}`;

		const params: AWS.S3.PutObjectRequest = {
			Bucket: environment.NOTICE_S3_AWS_BUCKET_NAME,
			Key: `${companyId}/${date}/${decodedFileName}`,
			Body: file,
			ContentDisposition: contentDisposition,
		};

		return new Promise((resolve, reject) => {
			this.s3.upload(params, (error, data) => {
				if (error) {
					reject(error);
				} else {
					resolve(true);
				}
			});
		});
	}

	// 이미지 추가
	async getInsertImage(file: File, date: string, fileName: string, companyId: string) {
		const encodedFileName = encodeURIComponent(fileName);
		const decodedFileName = decodeURIComponent(encodedFileName);

		const contentDispositionFileName = Buffer.from(decodeURIComponent(file.name), 'utf-8').toString(
			'base64'
		);
		const contentDisposition = `attachment; filename*=UTF-8${contentDispositionFileName}`;

		const params: AWS.S3.PutObjectRequest = {
			Bucket: environment.NOTICE_S3_AWS_BUCKET_NAME,
			Key: `${companyId}/${date}/${decodedFileName}`,
			Body: file,
			ACL: 'public-read',
			ContentDisposition: contentDisposition,
		};

		return new Promise((resolve, reject) => {
			this.s3.upload(params, (error, data) => {
				if (error) {
					reject(error);
				} else {
					resolve(data);
				}
			});
		});
	}

	// 게시글 첨부파일 다운로드
	async downloadAttachment(body: { bucket: string; s3_key: string }) {
		const url = environment.downloadAttachmentUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 첨부파일 삭제
	async deleteAttachment(body: { bucket: string; s3_key: string }) {
		const url = environment.deleteAttachmentUrl;
		const res = await this.http.post<any>(url, body).toPromise();
		return res;
	}

	// 공지 삭제
	async deleteNotice(noticeId: string, regDt: string) {
		const url = `${environment.deleteNoticeUrl}/${noticeId}?reg_dt=${regDt}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 그룹 3개월 데이터(리포트)
	async getGroupData(companyId: string, dateKey: string) {
		const url = `${environment.getGroupDataUrl}/${companyId}?date_key=${dateKey}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}

	// 그룹 월 데이터(리포트)
	async getGroupMonthlyData(groupId: string, dateKey: string) {
		const url = `${environment.getGroupMonthlyDataUrl}/${groupId}?date_key=${dateKey}`;
		const res = await this.http.get<any>(url).toPromise();
		return res;
	}
}
