import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	OnDestroy,
	ViewChildren,
	QueryList,
	ViewChild,
	ChangeDetectorRef,
	ViewContainerRef,
	TemplateRef,
} from '@angular/core';
import { Event, VideoEntryBundle } from '../../types/data-types';
import { DateFormats } from '../../uicommon';
import { stringifyLocation, parseLngLat as parseLatLng } from '../../types/common';
import { DataService } from '../../service/data.service';
import { AppContextService } from '../../app-context.service';
import { saveAs } from 'file-saver';
import { TripsService } from '../../service/trips.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from '../../service/alert.service';
import { CustomTranslateService } from 'src/app/service/customTranslate.service';
interface EventTableItem extends Event {
	date?: Date;
	address?: string;
}

@Component({
	selector: 'app-trip-event-table',
	templateUrl: './trip-event-table.component.html',
	styleUrls: ['./trip-event-table.component.scss'],
})
export class TripEventTableComponent implements OnInit, OnDestroy {
	@ViewChildren('listBox') listBox: QueryList<any>;
	@ViewChild('tableBox') tableBox;
	@ViewChild('itemsContainer', { read: ViewContainerRef }) container: ViewContainerRef;
	@ViewChild('item', { read: TemplateRef }) template: TemplateRef<any>;
	subs = new Subscription();
	alertFilterAll = true;
	alertFilterDeparture = false;
	alertFilterCollision = false;
	alertFilterAccel = false;
	alertFilterBrake = false;
	alertFilterStop = false;
	private readonly _stop = new Subject<void>();
	private readonly _start = new Subject<void>();
	clearSw = false;

	selectRow: string;

	DateFormats = DateFormats;
	scrollSw = true;
	stringifyLocation = stringifyLocation;

	tripEventDataFilter: any = [];
	count = 0;

	isIndustry = false;

	andong_account = false;

	newCount = 0;
	@Input('filter') filter;
	@Input() selectEvent: any = [];

	@Input('events')
	set setEventData(eventData: Event[]) {
		this.count = 0;
		this.container.clear();
		this.clearSw = false;
		this.tripEventDataFilter = eventData.map<EventTableItem>((e, index) => ({
			...e,
			date: new Date(Number(e.time_stamp)),
			index: index,
		}));
		this.tripEventDataFilter.sort((a, b) => {
			return b.date - a.date;
		});
		this._start.next();
		this.selectRow = null;

		// this.tripService.getIndexTrigger2().pipe(filter(value => !!value)).subscribe((value: string) => {
		//     this.selectRow = String(value);
		// });
		this.subs.add(
			this.tripService
				.getIndexTrigger()
				.pipe(filter((value) => !!value))
				.subscribe((value: { data1: string; data2: boolean }) => {
					this.scrollSw = value.data2;
					if (value.data1 === 'refresh') {
						this.selectRow = null;
					} else {
						let scroll_offset;
						if (
							!!this.listBox &&
							!!this.listBox['_results'] &&
							!!this.tableBox['nativeElement']['scrollHeight']
						) {
							scroll_offset =
								this.tableBox['nativeElement']['scrollHeight'] / this.listBox['_results'].length;
						}
						if (!!value.data1 && value.data1 !== 'undefined' && !!this.scrollSw) {
							this.selectRow = String(value.data1);
							const index = this.tripEventDataFilter.findIndex((raw) => {
								return raw.time_stamp === String(value.data1);
							});
							if (index > -1) {
								this.tableBox['nativeElement']['scrollTop'] = scroll_offset * index;
							}
							this.scrollSw = true;
						}
					}
				})
		);

		// event === 'Departure' || event === 'Crash Detected' || event === 'Collision'
		if (this.isIndustry) {
			this.tripEventDataFilter = this.tripEventDataFilter.filter((item: any) => {
				const event = item['event'];

				return (
					event === 'departure' ||
					event === 'Departure' ||
					event === 'collision' ||
					event === 'Collision' ||
					event === 'crash detected' ||
					event === 'Crash Detected' ||
					event === 'Severe Impact'
				);
			});
		}

		this.tripEventData = this.tripEventDataFilter;

		// 23년 8월 이후 발생한 20km 미만 전방충돌 경보 이벤트는 제거
		const start_date_value = new Date('2023-08-01 00:00:00.000').getTime();
		this.tripEventData.map((item: any, key: number) => {
			if (
				item['event'] === 'collision' &&
				item['speed'] < 20 &&
				new Date(item['rented_from']).getTime() >= start_date_value
			) {
				this.tripEventData.splice(key, 1);
			}
		});

		// console.log(this.tripEventDataFilter);

		if (this.tripEventData.length > 0) {
			this.tripEventData = this.tripEventData.filter(
				(item: any) => item['event'] !== 'School Zone'
			);

			this.tripEventDataFilter = this.tripEventDataFilter.filter(
				(item: any) => item['event'] !== 'School Zone'
			);

			console.log('운행 위험요소 기록  ', this.tripEventData);
		}
	}

	@Output() refresh = new EventEmitter();
	@Output() mapEventFocus = new EventEmitter();

	tripEventData: EventTableItem[] = [];

	playPopup = false;
	popupVideo: VideoEntryBundle;

	spinner = {
		show() {},
		hide() {},
	};
	eventVideo;
	time_id;
	lang = '';
	transObj = {};

	@Input() isRefresh: boolean;

	constructor(
		private ds: DataService,
		public appContext: AppContextService,
		private tripService: TripsService,
		private alertService: AlertService,
		private customTranslateService: CustomTranslateService,
		private cf: ChangeDetectorRef
	) {}

	clickRefresh() {
		if (!this.clearSw && this.tripEventDataFilter.length > 0) {
			this.alertService.alertDialog(
				this.transObj['ALERTS']['Generating data. Please try again later.']
			);
			return false;
		}

		this.refresh.emit();
		this.tripService.setIndexTrigger('refresh', undefined);
		this.tripService.setTripEventTrigger('refresh', 'refresh');
	}
	ngOnInit() {
		console.log('운행 위험요소 기록 shared/trip-event-table');

		if (localStorage.getItem('andong_company') === 'true') {
			this.andong_account = true;
		}

		if (
			localStorage.getItem('company_id') === 'fffbbbe7-fd85-45de-a91e-5dc7bc935547' ||
			localStorage.getItem('industry_id') === '연비절감사업' ||
			localStorage.getItem('industry_id') === '공영버스'
		) {
			this.isIndustry = true;
		}

		this.subs.add(
			this.customTranslateService
				.getLang()
				.pipe(
					switchMap((value) => {
						this.lang = value ? value : 'ko';
						return this.customTranslateService.sendJson(this.lang);
					})
				)
				.subscribe((value) => {
					this.transObj = value;
				})
		);
		this.tripService
			.getTripVideoTrigger3()
			.pipe(
				filter((value) => !!value),
				tap((value) => (this.eventVideo = value)),
				switchMap((event) => this.ds.getCameraStatusByID2(event))
			)
			.subscribe((res) => {
				if (res.status == 'success') {
					this.popupVideo = {
						...this.eventVideo,
						url: res.message.video_url,
					};
					this.popupVideo = { ...this.popupVideo };
					this.playPopup = true;
					this.cf.markForCheck();
				}
			});

		this.tripService.getRefrashLIst().subscribe((value: object[]) => {
			if (value.length > 0) {
				const changeList = value.filter((data, index) => {
					return data['video_status'] !== this.tripEventDataFilter[index]['video_status'];
				});
				if (changeList.length > 0) {
					changeList.forEach((data) => {
						const index = this.tripEventDataFilter.findIndex((event) => {
							return event['time_stamp'] === data['time_stamp'];
						});
						if (index > -1) {
							this.tripEventDataFilter[index]['video_status'] = data['video_status'];
						}
					});
				}
			}
		});
		this._start.pipe(filter(() => this.tripEventDataFilter.length > 0)).subscribe(() => {
			setTimeout(() => {
				if (!this.clearSw) {
					const e = this.tripEventDataFilter[this.count];
					if (this.count === 0) {
						this.tripService.setLoadingTrigger(false);
						this.container.clear();
					}
					this.container.createEmbeddedView(this.template, { $implicit: e });
					if (this.count === this.tripEventDataFilter.length - 1) {
						console.log('timer stop');
						this.clearSw = true;
						this.tripService.setLoadingTrigger(true);
						this.cf.markForCheck();
					} else {
						this.count++;
						this._start.next();
					}
					this.cf.markForCheck();
				}
			});
		});

		// console.log(this.tripEventDataFilter);
		//this.requestVideo();
	}

	mapFocus(e, timestamp: any, i, event) {
		if (!this.clearSw) {
			this.alertService.alertDialog(
				this.transObj['ALERTS']['Generating data. Please try again later.']
			);
			return false;
		}
		if (event.target.id === 'btn' || event.target.nodeName === 'IMG') {
			return false;
		}

		const data = this.tripEventDataFilter[i].time_stamp;
		this.selectRow = String(timestamp);
		this.scrollSw = false;
		this.tripService.setIndexTrigger(this.selectRow, this.scrollSw);
		this.selectEvent = e;
		this.mapEventFocus.emit(this.selectEvent);
		this.tripService.setTripEventTrigger(e, timestamp);
	}
	getTripEventText(t: Event) {
		if (t.event) {
			if (t.event == 'sudden') {
				if (t['situation'] == 'brake') {
					t.event = 'Hard Braking';
				} else if (t['situation'] == 'accel') {
					t.event = 'Hard Acceleration';
				} else {
					t.event = 'Sudden Stop';
				}
			} else if (t['event'] == 'collision') {
				t.event = 'Collision';
			} else if (t['event'] == 'departure') {
				t.event = 'Departure';
			} else if (t['event'] == 'Crash Detected') {
				t.event = 'Severe Impact';
			}
			return t.event;
		} else {
			console.log('xxx ==> ', t);
		}
	}

	async download(video: VideoEntryBundle) {
		const res = await this.ds.getCameraStatusByID(video);
		if (res.status == 'success') {
			saveAs(res.message.video_url);
		}
	}

	share(e) {}

	bookmark(e) {}

	requestVideo(video: any) {
		if (!this.clearSw) {
			return false;
		}
		if (video.video_file == null) {
			this.alertService.alertDialog('file path error');
			return false;
		}
		// this.ds.getCameraAlertByID(video, "event").then(res => {
		this.ds.requestDeviceVideo(video, 'event').then((res) => {
			console.log(res);
			let file_none = false;
			if (res['message'] == 'Video Not available at this moment') {
				file_none = true;
			}
			if (res['status'] == 'success') {
				var message;
				if (res['active'] == true) {
					message = this.transObj['ALERTS']['video request to active device'];
				} else {
					message = this.transObj['ALERTS']['video request to inactive device'];
				}
				this.alertService.alertDialog(message);
				const vdo_status: number = Number(res['message']['vdo_status']) || 0;
				const data = {
					status: vdo_status,
					time_stamp: String(video['time_stamp']),
				};
				this.tripService.setListbuttonStatus(data);
				//video.video_status = res['message']['vdo_status'];
			} else if (file_none == true) {
				this.alertService.alertDialog(this.transObj['ALERTS']['Request Fail']);
				const vdo_status: number = 3;
				const data = {
					status: vdo_status,
					time_stamp: String(video['time_stamp']),
				};
				this.tripService.setListbuttonStatus(data);
			} else {
				let items = res['message']['Items'];
				let items_message = '';
				for (var i = 0; i < items.length; i++) {
					let path = items[i]['video_file'].split('/');
					items_message = items_message + '\n' + path[path.length - 1];
				}
				this.alertService.alertDialog(res['message']['message'] + items_message);
			}
		});
	}
	ngOnDestroy() {
		clearInterval(this.time_id);
		this.subs.unsubscribe();
	}

	playVideo(event: any) {
		if (!event.video_file || event.video_file == 'null') {
			return;
		}
		if (!this.clearSw) {
			return false;
		}
		if (event.event == 'sudden') {
			if (event['situation'] == 'brake') {
				event.event = 'Hard Braking';
			} else if (event['situation'] == 'accel') {
				event.event = 'Hard Acceleration';
			} else {
				event.event = 'Sudden Stop';
			}
		} else if (event['event'] == 'collision') {
			event.event = 'Collision';
		} else if (event['event'] == 'departure') {
			event.event = 'Departure';
		}
		this.tripService.setTripVideoTrigger3(event);
	}
	handleChangeAlertFilter(eventName) {
		// console.log(`alertFilterAll: ${this.alertFilterAll}`);
		// console.log(`alertFilterDeparture: ${this.alertFilterDeparture}`);
		// console.log(`alertFilterCollision: ${this.alertFilterCollision}`);
		// console.log(`alertFilterAccel: ${this.alertFilterAccel}`);
		// console.log(`alertFilterBrake: ${this.alertFilterBrake}`);
		// console.log(`alertFilterStop: ${this.alertFilterStop}`);
		console.log(eventName);
		if (eventName == 'all') {
			this.alertFilterAll = true;
			this.alertFilterDeparture = false;
			this.alertFilterCollision = false;
			this.alertFilterAccel = false;
			this.alertFilterBrake = false;
			this.alertFilterStop = false;
		}
		if (
			this.alertFilterDeparture ||
			this.alertFilterCollision ||
			this.alertFilterCollision ||
			this.alertFilterAccel ||
			this.alertFilterBrake ||
			this.alertFilterStop
		) {
			this.alertFilterAll = false;
		}

		this.tripEventDataFilter = this.tripEventData.filter((e) => {
			return (
				this.alertFilterAll ||
				(this.alertFilterDeparture && e.event == 'Departure') ||
				(this.alertFilterCollision && e.event == 'Collision') ||
				(this.alertFilterAccel && e.event == 'Hard Acceleration') ||
				(this.alertFilterBrake && e.event == 'Hard Braking') ||
				(this.alertFilterStop && e.event == 'Sudden Stop')
			);
		});
	}
}
