import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	OnDestroy,
	ChangeDetectionStrategy,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Trip } from '../../types/data-types';
import { stringifyLocation } from '../../types/common';
import { DateFormats } from '../../uicommon';
import { DataService } from '../../service/data.service';
import { AppContextService } from '../../app-context.service';
import { CustomTranslateService } from '../../service/customTranslate.service';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import dayjs from 'dayjs';
export interface TripHistoryItem extends Trip {
	index?: number;
	name?: string;
	vehicle?: string;
	startDate?: string;
	endDate?: string;
	duration?: any;
	distance?: number; // km
	eventCount?: number;
	startLoc?: string;
	alert_count?: number;
	startAddress?: string;
	endLoc?: string;
	endAddress?: string;
}

@Component({
	selector: 'app-trip-history-table2',
	templateUrl: './trip-history-table.component.html',
	styleUrls: ['./trip-history-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripHistoryTableComponent2 implements OnInit, OnDestroy, OnChanges {
	subs = new Subscription();
	selectRow: number;
	DateFormats = DateFormats;
	stringifyLocation = stringifyLocation;

	total_event = 0;
	total_mileage = 0;
	tripSuject$ = new BehaviorSubject<Trip[]>(null);
	@Input('trips')
	set setTripData(trips: Trip[]) {
		this.tripSuject$.next(trips);
	}
	sorted: any = {};

	@Input() range: any;

	@Output()
	tripSelected = new EventEmitter<Trip>();
	@Input('selectIndex')
	selectIndex = '';

	@Input() rangeTripList: any;
	tripData: TripHistoryItem[] = [];

	isIndustry = false;
	busCompany = false;

	rental_from: string;
	rental_to: string;
	companyId: string;
	driverId: string;
	support_ws = false;
	tripListData: any = [];
	isLoaded = false;
	deviceId: string;
	prevType: string;

	@Input()
	showDriver = true;
	langObj: object = {};
	lang = '';

	andong_account = false;
	gsDashboard = false;

	constructor(
		private ds: DataService,
		public appContext: AppContextService,
		private customTranslateService: CustomTranslateService,
		private route: ActivatedRoute,
		private spinner: Ng4LoadingSpinnerService,
		private changeRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		console.log('주행기록 shared/trip-history-table2 ');

		if (localStorage.getItem('andong_company') === 'true') {
			this.andong_account = true;
		}

		if (this.gsDashboard) {
			this.andong_account = true;
		}

		if (
			localStorage.getItem('company_id') === 'fffbbbe7-fd85-45de-a91e-5dc7bc935547' ||
			localStorage.getItem('industry_id') === '연비절감사업'
		) {
			this.isIndustry = true;
		}

		if (localStorage.getItem('industry_id') === '공영버스') {
			this.busCompany = true;
		}

		if (localStorage.getItem('support_ws') === 'true') {
			this.support_ws = true;
		}

		if (this.route.snapshot.params['id']) {
			this.driverId = this.route.snapshot.params['id'];
		}

		this.route.queryParams.subscribe((params) => {
			const { company_id, rental_from, rental_to, prev_type } = params;
			if (params) {
				this.companyId = company_id;
				this.rental_from = rental_from;
				this.rental_to = rental_to;
				this.prevType = prev_type;
			}
		});

		this.subs.add(
			this.customTranslateService
				.getLang()
				.pipe(
					switchMap((value) => {
						const defaultLang = localStorage.getItem('defaultLng');
						if (!!this.lang) {
							if (this.lang !== defaultLang) {
								this.spinner.show();
							}
						}

						this.lang = value ? value : 'ko';
						return this.customTranslateService.sendJson(this.lang);
					})
				)
				.subscribe((value) => {
					if (!!value) {
						this.spinner.hide();
					}
				})
		);

		this.getRangeTripList();
	}

	// 차량 페이지에서 넘어온 경우
	getRangeTripList() {
		this.spinner.show();
		if (!this.driverId || !this.rental_from || !this.rental_to) {
			return;
		}

		const startDt = dayjs(this.rental_from).format('YYYYMMDD');
		const endDt = dayjs(this.rental_to).format('YYYYMMDD');

		if (this.prevType === 'vehicle') {
			// 차량 페이지에서 넘어온 경우
			this.subs.add(
				forkJoin(this.ds.getRangeTripsOld(this.driverId, startDt, endDt)).subscribe(([res]) => {
					if (res.status === 'success') {
						this.processTripListData(res.data.listTripRangeDriver.items);
					}
				})
			);
		} else {
			// 운전자 상세페이지에서 넘어온 경우
			this.subs.add(
				forkJoin(this.ds.getRangeTrips(this.driverId, startDt, endDt)).subscribe(([res]) => {
					if (res.status === 'success') {
						this.processTripListData(res.data.listTripRangeDriver.items);
					}
				})
			);
		}
	}

	processTripListData(trips: any[]) {
		if (!trips || trips.length === 0) {
			this.tripListData = [];
			this.spinner.hide();
			return;
		}

		this.tripListData = trips.map((item) => ({
			...item,
			driver_name: this.getDriverName(item),
			startDate: dayjs(item['trip_start']).format('MM/DD/YYYY, HH:mm:ss'),
			endDate: dayjs(item['trip_end']).format('MM/DD/YYYY, HH:mm:ss'),
			duration: this.getTripDuration(item),
			startLocation: this.getTripLocation(item, 'source'),
			endLocation: this.getTripLocation(item, 'destination'),
			distance: this.getTripDistance(item),
			riskCount: this.calculateRiskCount(item),
			startLoc: !!item.trip_details['location']['source']['geocode']
				? item.trip_details['location']['source']['geocode']
				: '-',
			endLoc: !!item.trip_details['location']['destination']['geocode']
				? item.trip_details['location']['destination']['geocode']
				: '-',
		}));

		this.isLoaded = true;
		this.spinner.hide();
		this.changeRef.markForCheck();
	}

	getDriverName(item: any): string {
		let driverName = '';

		if (item.driver_name_matched) {
			driverName = item.driver_name_matched;

			if (localStorage.getItem('DashTitle').includes('케이원')) {
				const name = item.driver_name_matched.split('_');
				item.driver_name_matched = name.length > 1 ? name[1] : item.driver_name_matched;
			}
		} else {
			driverName = 'UNKNOWN';
		}

		return driverName;
	}

	getTripDuration(item: any): string {
		const hour = parseInt(`${item.trip_time / 3600}`);
		const minute = parseInt(`${(item.trip_time % 3600) / 60}`);
		const second = Math.round(item.trip_time % 60);
		const duration = `${new Intl.NumberFormat().format(hour)}:${String(minute).padStart(
			2,
			'0'
		)}:${String(second).padStart(2, '0')}`;

		return duration;
	}

	getTripLocation(item: any, type: 'source' | 'destination'): string {
		if (item['trip_details'] && item['trip_details']['location']) {
			const location = item['trip_details']['location'][type]['geocode']['ko'];
			return location ? location['address'] : '-';
		}
	}

	getTripDistance(item: any): string {
		if (item['trip_details'] && item['trip_details']['stats']) {
			return item['trip_details']['stats']['mileage'];
		} else {
			return '-';
		}
	}

	calculateRiskCount(item: any): number {
		const events = item['nevents'];
		let riskCount = 0;

		if (this.isIndustry || this.busCompany) {
			riskCount =
				events['collision']['fst_warning'] +
				events['collision']['snd_warning'] +
				events['departure']['left'] +
				events['departure']['right'];
		} else {
			riskCount =
				events['collision']['fst_warning'] +
				events['collision']['snd_warning'] +
				events['departure']['left'] +
				events['departure']['right'] +
				events['sudden']['accel'] +
				events['sudden']['brake'] +
				events['sudden']['stop'];

			if (!this.andong_account) {
				riskCount += events['mild_impact'];
			}
		}

		return riskCount;
	}

	sortColumn(col: string, order: number) {
		this.sorted[col] = order;
		this.tripData.sort((lhs, rhs) => {
			return (lhs[col] - rhs[col]) * order;
		});

		// this.tripListData.sort((lhs, rhs) => {
		// 	return (lhs[col] - rhs[col]) * order;
		// });
	}
	inputData(rowData, i: number) {
		console.log('trip');
		// console.log(rowData);
		// console.log(i);
		this.selectRow = i;
		this.tripSelected.emit(rowData);
		this.changeRef.markForCheck();
		// console.log(rowData);
		// console.log(i);
		// this.deviceId = rowData['device_id'];
	}

	// risk
	nevents_risk_filter_moto = [
		'sudden.accel',
		'sudden.brake',
		'sudden.stop',
		'mild_impact',
		'sharpturn.left',
		'sharpturn.right',
	];
	nevents_risk_filter_normal = [
		'departure.left',
		'departure.right',
		'collision.fst_warning',
		'collision.snd_warning',
		'sudden.accel',
		'sudden.brake',
		'sudden.stop',
		'mild_impact',
	];

	nevents_risk_filter_andong = [
		'departure.left',
		'departure.right',
		'collision.fst_warning',
		'collision.snd_warning',
		'sudden.accel',
		'sudden.brake',
		'sudden.stop',
	];

	nevents_risk_filter_industry = [
		'departure.left',
		'departure.right',
		'collision.fst_warning',
		'collision.snd_warning',
	];

	// event
	nevents_event_filter_moto = [
		'crash_detected',
		'fall.left',
		'fall.right',
		'sigvio.atred',
		'sigvio.atyellow',
	];
	nevents_event_filter_normal = ['crash_detected', 'smoke', 'drunk'];

	getRiskCount(vehicle) {
		var riskCount = 0;
		var riskFilter;

		if (this.isIndustry || this.busCompany) {
			riskFilter = this.nevents_risk_filter_industry;
		} else {
			if (localStorage.getItem('company_type') == 'moto') {
				riskFilter = this.nevents_risk_filter_moto;
			} else {
				if (this.andong_account) {
					riskFilter = this.nevents_risk_filter_andong;
				} else {
					riskFilter = this.nevents_risk_filter_normal;
				}
			}
		}

		if ('nevents' in vehicle) {
			let nevents = vehicle.nevents;
			for (var item of riskFilter) {
				let keys = item.split('.');
				let depth = keys.length;

				try {
					if (depth == 1) {
						if (nevents[keys[0]] != null && nevents[keys[0]] != undefined) {
							riskCount += nevents[keys[0]];
						}
					} else if (depth == 2) {
						if (nevents[keys[0]][keys[1]] != null && nevents[keys[0]][keys[1]] != undefined) {
							riskCount += nevents[keys[0]][keys[1]];
						}
					} else if (depth == 3) {
						if (
							nevents[keys[0]][keys[1]][keys[2]] != null &&
							nevents[keys[0]][keys[1]][keys[2]] != undefined
						) {
							riskCount += nevents[keys[0]][keys[1]][keys[2]];
						}
					} else if (depth == 4) {
						if (
							nevents[keys[0]][keys[1]][keys[2]][keys[3]] != null &&
							nevents[keys[0]][keys[1]][keys[2]][keys[3]] != undefined
						) {
							riskCount += nevents[keys[0]][keys[1]][keys[2]][keys[3]];
						}
					}
				} catch {
					// exception �� �׸��� ����.
				}
			}
			return riskCount;
		} else {
			return 0;
		}
	}
	getEventCount(vehicle) {
		var eventCount = 0;
		var eventFilter;
		if (localStorage.getItem('company_type') == 'moto') {
			eventFilter = this.nevents_event_filter_moto;
		} else {
			eventFilter = this.nevents_event_filter_normal;
		}

		if ('nevents' in vehicle) {
			let nevents = vehicle.nevents;
			for (var item of eventFilter) {
				let keys = item.split('.');
				let depth = keys.length;

				try {
					if (depth == 1) {
						if (nevents[keys[0]] != null && nevents[keys[0]] != undefined) {
							eventCount += nevents[keys[0]];
						}
					} else if (depth == 2) {
						if (nevents[keys[0]][keys[1]] != null && nevents[keys[0]][keys[1]] != undefined) {
							eventCount += nevents[keys[0]][keys[1]];
						}
					} else if (depth == 3) {
						if (
							nevents[keys[0]][keys[1]][keys[2]] != null &&
							nevents[keys[0]][keys[1]][keys[2]] != undefined
						) {
							eventCount += nevents[keys[0]][keys[1]][keys[2]];
						}
					} else if (depth == 4) {
						if (
							nevents[keys[0]][keys[1]][keys[2]][keys[3]] != null &&
							nevents[keys[0]][keys[1]][keys[2]][keys[3]] != undefined
						) {
							eventCount += nevents[keys[0]][keys[1]][keys[2]][keys[3]];
						}
					}
				} catch {
					// exception �� �׸��� ����.
				}
			}
			return eventCount;
		} else {
			return 0;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.rangeTripList && this.rangeTripList.length > 0) {
			this.processTripListData(this.rangeTripList);
		} else {
			this.tripListData = [];
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
}
